import { Button, Col, Flex, Typography } from "antd";
import { Colors } from "constants/colors";
import { useAppSelector } from "store/hooks";
import { selectProject } from "store/projectSlice";
import styled from "styled-components";
import { useContext, useEffect, useState } from "react";
import { SequenceContext } from "./SequenceList";

const { Title } = Typography;

export interface ICondition {
    setActiveCard: (item: number | null) => void;
    activeCard?: number | null;
    play?: boolean;
    setPlay?: (item: boolean) => void;
}

export const DevicesAndConditions = ({ play }: { play: boolean }) => {
    const { temporaryProject } = useAppSelector(selectProject);
    const { nodes, edges } = temporaryProject.template || {};
    const { sequence, setSequence } = useContext(SequenceContext);
    const [activeDevice, setActiveDevice] = useState<any>(null);

    const filteredElements = nodes?.filter((node: any) =>
        node.node.connectors.some((connector: any) => {
            const hasConnectedEdge = edges?.some(
                (edge: any) =>
                    Number(edge.sourceHandle) === connector.id ||
                    Number(edge.targetHandle) === connector.id
            );
            const isRelevantConnection = [1, 2].includes(
                connector.connection_type_id
            );
            // const isRelevant = sequence?.find(
            //     (item: any) => item?.deviceId === node?.id
            // );
            return (
                isRelevantConnection &&
                connector?.type === "target" &&
                hasConnectedEdge
                // (!isRelevant ||
                //     isRelevant?.outputId?.length !==
                //         isRelevant?.showDevideInChoose)
            );
        })
    );

    useEffect(() => {
        setActiveDevice(filteredElements);
    }, [sequence]);

    const addCondition = (device: any, type: string) => {
        const { type: typeDevice, id, data } = device || {};
        setSequence(
            sequence.concat(
                type === "device"
                    ? {
                          text: typeDevice,
                          id: sequence?.length + 1,
                          deviceId: id,
                          type: type,
                          label: data?.label,
                          inputId: [],
                          outputId: [],
                      }
                    : {
                          text: typeDevice,
                          id: sequence?.length + 1,
                          type: type,
                          label: data?.label,
                      }
            )
        );
    };

    return (
        <Col>
            <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
                Choose:
            </Title>
            <Flex vertical gap={8}>
                {conditions?.map((condition) => (
                    <BlockAdd align="center">
                        <Button
                            type="link"
                            className="block_condition"
                            disabled={condition?.disabled || play}
                            onClick={() => addCondition(condition, "condition")}
                        >
                            {condition.label}
                        </Button>
                    </BlockAdd>
                ))}
                {activeDevice?.map((node: any) => (
                    <BlockAdd align="center">
                        <Button
                            type="link"
                            className="block_condition block_device"
                            onClick={() => addCondition(node, "device")}
                            disabled={play}
                        >
                            {node?.data?.label}
                        </Button>
                    </BlockAdd>
                ))}
            </Flex>
        </Col>
    );
};

const BlockAdd = styled(Flex)`
    .block_condition {
        display: flex;
        padding: 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        background-color: ${Colors.MainBlue500};
        height: 50px;
        min-width: 150px;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        color: ${Colors.MainWait500};
        border-radius: 8px;
    }
    .add_condition {
        display: none;
    }
    .block_device {
        background-color: ${Colors.MainPurple400} !important;
    }
    &:hover .add_condition {
        display: flex;
        cursor: pointer;
    }
    &:hover .block_condition {
        background-color: ${Colors.MainBlue200} !important;
        color: ${Colors.MainBlue700} !important;
        transition: 0.3s linear;
    }
    &:hover .block_device {
        background-color: ${Colors.MainPurple200} !important;
        color: ${Colors.MainPurple600} !important;
        transition: 0.3s linear;
    }
`;

const conditions = [
    {
        label: "Wait",
        type: "Wait",
        condition: true,
        disabled: false,
    },
    // {
    //     label: "If",
    //     type: "If",
    //     condition: true,
    //     disabled: true,
    // },
    // {
    //     label: "Go to",
    //     type: "Go to",
    //     condition: true,
    //     disabled: true,
    // },
    // {
    //     label: "End",
    //     type: "End",
    //     condition: true,
    //     disabled: true,
    // },
];
