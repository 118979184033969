import { ReactFlow, ReactFlowProvider } from "@xyflow/react";
import { useEffect, useRef } from "react";
import { Project } from "shared/interfaces";
import { customNodes } from "../Nodes";
import { Modal, Spin } from "antd";
import { toJpeg, toPng } from "html-to-image";
import styled from "styled-components";
import { openNotification } from "../Notification";
import { TypeConnections } from "shared/components";

export type TDownloadType = "png" | "jpeg" | "";

interface IDownloadBlockProps {
    data: Project;
    download: TDownloadType;
    setDownload: (open: TDownloadType) => void;
}

export const ImageDownloadBlock = (props: IDownloadBlockProps) => {
    const reactFlowWrapper = useRef<any>(null);
    const { data, download, setDownload } = props;
    const { edges, nodes } = data?.template || {};

    useEffect(() => {
        if (download) {
            setTimeout(() => {
                downloadImage(download, reactFlowWrapper);
            }, 0);
        }
    }, [download]);

    const downloadImage = async (
        format: "png" | "jpeg",
        reactFlowWrapper: any
    ) => {
        if (reactFlowWrapper.current) {
            // Ensure all elements are rendered
            await new Promise((resolve) => setTimeout(resolve, 500))
                .then(async () => {
                    reactFlowWrapper.current.style.backgroundColor = "#fff";
                    const dataUrl = await (format === "png"
                        ? toPng(reactFlowWrapper.current, { skipFonts: true })
                        : toJpeg(reactFlowWrapper.current, {
                              quality: 0.95,
                              skipFonts: true,
                          }));

                    const link = document.createElement("a");
                    link.href = dataUrl;
                    link.download = `${data.name}.${format}`;
                    link.click();
                })
                .then(() => {
                    openNotification("success");
                })
                .catch(() => openNotification("error"))
                .finally(() => {
                    setDownload("");
                });
        }
    };

    return (
        <ModalStyled
            open={!!download}
            width={"100%"}
            footer={null}
            wrapClassName="custom-modal"
        >
            <Spin
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                }}
            />
            <div
                className="providerflow"
                style={{ height: "100%", width: "100%", opacity: 0 }}
            >
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={customNodes(nodes)}
                            fitView
                            maxZoom={2.5}
                            zoomOnScroll={false}
                        />
                        <TypeConnections />
                    </div>
                </ReactFlowProvider>
            </div>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    top: 0;
    padding: 0;
    height: 100%;
    maxwidth: 100%;
    .ant-modal-content {
        background-color: transparent;
        box-shadow: none;
        height: 100vh;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-body {
        height: 100%;
    }
`;
