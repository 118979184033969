import { Col, Flex, Typography } from "antd";
import { EmptyData } from "./SequenceModal";
import { DndProvider, useDrag, useDrop, XYCoord } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import update from "immutability-helper";
import { Colors } from "constants/colors";
import styled from "styled-components";
import { DeleteIcon, MoveIcon } from "../icons";
import { ICondition } from "./DevicesAndConditions";
import { SequenceContext } from "./SequenceList";

const { Text, Title } = Typography;
export interface Item {
    id: number;
    text: string;
}

export interface ContainerState {
    cards: Item[];
}

export const ListChoosed = (props: ICondition) => {
    const { activeCard, setActiveCard, play, setPlay } = props;
    const { sequence, setSequence } = useContext(SequenceContext);
    const [activePlay, setActivePlay] = useState<number | null>(1);
    const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);

    const playbackRef = useRef<NodeJS.Timeout | null>(null);
    const intervalRef = useRef<NodeJS.Timeout | null>(null);

    const clearPlayback = () => {
        if (playbackRef.current) {
            clearTimeout(playbackRef.current);
            playbackRef.current = null;
        }
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const handleStop = () => {
        clearPlayback();
        if (setPlay) {
            setPlay(false);
        }
        setElapsedSeconds(0);
        setActivePlay(null);
    };

    useEffect(() => {
        if (!play || !sequence || sequence.length === 0) return;
        setActiveCard(null);
        let currentIndex = 0;

        const playSequence = () => {
            // Skip all steps with `checked === true`
            while (
                sequence[currentIndex] &&
                sequence[currentIndex].checked === true
            ) {
                currentIndex += 1;
            }

            const active = sequence[currentIndex];
            if (active) {
                setActivePlay(currentIndex);

                const isWait = active.typeCondition === "wait";
                const delay = isWait ? +active.timer * 1000 || 1000 : 1000;

                if (!intervalRef.current) {
                    intervalRef.current = setInterval(() => {
                        setElapsedSeconds((prev) => prev + 1);
                    }, 1000);
                }

                playbackRef.current = setTimeout(() => {
                    currentIndex += 1;
                    if (currentIndex < sequence.length) {
                        playSequence(); // Recursive call to continue the sequence
                    } else {
                        handleStop(); // Stop when sequence ends
                    }
                }, delay);
            } else {
                handleStop();
            }
        };

        playSequence();

        return () => {
            handleStop();
        };
    }, [play, sequence]);

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            setSequence((prevCards: Item[]) =>
                update(prevCards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, prevCards[dragIndex] as Item],
                    ],
                })
            );
        },
        [setSequence]
    );

    const deleteCard = (id: number) => {
        setSequence((prevItems: Item[]) =>
            prevItems.filter((item: Item) => item.id !== id)
        );
    };

    const renderCard = useCallback(
        (card: { id: number; text: string }, index: number) => {
            return (
                <Card
                    key={card.id}
                    index={index}
                    id={card.id}
                    data={card}
                    moveCard={moveCard}
                    deleteCard={deleteCard}
                    setActiveCard={setActiveCard}
                    activeCard={activeCard}
                    activePlay={activePlay}
                    play={play}
                />
            );
        },
        [activeCard, activePlay, setActiveCard, play]
    );

    return (
        <Col
            style={{
                height: "100%",
                borderLeft: "1px solid #D2D2D7",
                borderRight: "1px solid #D2D2D7",
                padding: "0 32px",
                margin: "0 32px",
            }}
        >
            <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
                Settings: {elapsedSeconds || ""} {!!elapsedSeconds && "seconds"}
            </Title>
            {sequence?.length === 0 && <EmptyData />}
            {sequence && sequence.length > 0 && (
                <DndProvider backend={HTML5Backend}>
                    <div style={{ width: "100%" }}>
                        {sequence.map((card: any, i: number) =>
                            renderCard(card, i)
                        )}
                    </div>
                </DndProvider>
            )}
        </Col>
    );
};

export interface CardProps {
    id: any;
    text: string;
    index: number;
    moveCard: (dragIndex: number, hoverIndex: number) => void;
    deleteCard: (id: number) => void;
    setActiveCard: (id: number) => void;
}

interface DragItem {
    index: number;
    id: string;
    type: string;
}

export const Card = ({
    id,
    data,
    index,
    moveCard,
    deleteCard,
    setActiveCard,
    activeCard,
    activePlay,
    play,
}: any) => {
    const { text, type, label, outputLabel, timer } = data || {};
    const ref = useRef<HTMLDivElement>(null);
    const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: any }>({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            };
        },
        hover(item: DragItem, monitor) {
            if (!ref.current) {
                return;
            }
            const dragIndex = item.index;
            const hoverIndex = index;
            if (dragIndex === hoverIndex) {
                return;
            }
            const hoverBoundingRect = ref.current?.getBoundingClientRect();
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
            const clientOffset = monitor.getClientOffset();
            const hoverClientY =
                (clientOffset as XYCoord).y - hoverBoundingRect.top;
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return;
            }
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return;
            }
            moveCard(dragIndex, hoverIndex);
            item.index = hoverIndex;
        },
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index };
        },
        canDrag: () => !play,
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0 : 1;
    drag(drop(ref));

    return (
        <CardStyled
            ref={ref}
            style={{
                opacity,
                backgroundColor: data?.checked
                    ? "#e7e7e7"
                    : activeCard === id || (activePlay === index && play)
                    ? Colors.MainBlue50
                    : "",
                borderBottom:
                    activeCard === id || (activePlay === index && play)
                        ? `1px solid ${Colors.MainDark500}`
                        : `1px solid ${Colors.MainDark100}`,
            }}
            data-handler-id={handlerId}
            align="center"
            onClick={() => !play && setActiveCard(id)}
            justify="space-between"
        >
            <Flex gap={16} align="center">
                <MoveIcon />
                {index + 1}
                <BlockAdd
                    style={{
                        backgroundColor:
                            type === "condition"
                                ? Colors.MainBlue500
                                : Colors.MainPurple400,
                    }}
                >
                    {label || text}
                </BlockAdd>
                <Text>
                    {text === "Wait"
                        ? `${timer || 0} seconds`
                        : text === "Go to"
                        ? ""
                        : `${outputLabel || ""}`}
                </Text>
            </Flex>
            <DeleteIcon
                onClick={() => !play && deleteCard(id)}
                style={{ cursor: "pointer" }}
            />
        </CardStyled>
    );
};

export const ItemTypes = {
    CARD: "card",
};

const CardStyled = styled(Flex)`
    padding: 16px 0;
    background-color: white;
    cursor: move;
    padding-right: 8px;
    transition: 0.4s linear;
    &:hover {
        background-color: ${Colors.MainBlue50};
    }
`;

const BlockAdd = styled(Flex)`
    display: flex;
    padding: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    background-color: ${Colors.MainBlue500};
    height: 34px;
    min-width: 90px;
    max-width: 200px
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: ${Colors.MainWait500};
    border-radius: 8px;
`;
