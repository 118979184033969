import {
    Button,
    Checkbox,
    Col,
    Divider,
    Dropdown,
    Empty,
    Flex,
    Input,
    Menu,
    Row,
    Select,
    Spin,
    Typography,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { debounce, map } from "lodash";
import { Colors } from "constants/colors";
import { ChangeEvent, useState } from "react";
import { SideBarModal } from "./SideBarModal";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    getData,
    selectAppliances,
    updateFilters,
    updateSorter,
} from "store/appliancesSlice";
import { openNotification, SelectStyled } from "shared/components";
import { AddPlus } from "shared/icons";
import { Device, ROLES } from "shared/interfaces";
import {
    getProject,
    selectProject,
    updateProject,
    updateSaved,
} from "store/projectSlice";
import { MoreOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { useParams } from "react-router-dom";
import { PreviewModal } from "./PreviewModal";
import { useDnD } from "../DndProvider";
import { selectUser } from "store/userSlice";

const { Text, Paragraph } = Typography;
const { Option } = Select;

const baseUrl = process.env.REACT_APP_API_URL_IMAGE;

export const DevicesPanel = () => {
    const dispatch = useAppDispatch();
    const { sideBarData, fetchStatus, connection_types, categories } =
        useAppSelector(selectAppliances);
    const { userInfo, roles } = useAppSelector(selectUser);
    const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
    const { temporaryProject, saved } = useAppSelector(selectProject);
    const isLoading = fetchStatus === "fetching" || fetchStatus === "init";
    const { t } = useTranslation();
    const { id: idProject } = useParams();

    const [open, setOpen] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);

    const [_, setType] = useDnD();

    const onDragStart = (
        event: React.DragEvent<HTMLDivElement>,
        nodeType: any
    ) => {
        if (setType) {
            setType(nodeType);
        }
        event.dataTransfer.effectAllowed = "move";
    };

    const handleChangeSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
        dispatch(updateFilters({ searchText: e.target.value }));
        dispatch(getData());
    }, 1000);

    const [selectedCategory, setCategory] = useState<number[]>([]);
    const [selectedConnection, setConnection] = useState<number[]>([]);

    const onChange = (value: number[], type: "category" | "connection") => {
        if (type === "connection") {
            setConnection(value);
        } else {
            setCategory(value);
        }
        dispatch(
            updateSorter({
                connection_types:
                    type === "connection" ? value : selectedConnection,
                categories: type === "category" ? value : selectedCategory,
            })
        );
        dispatch(getData());
    };

    const handleDeleteClick = async (id: number) => {
        const deleteDevice = () =>
            axios
                .delete(ENDPOINTS.deviceById(String(id)))
                .then(() => {
                    openNotification("success");
                    setTimeout(() => dispatch(getData()), 1000);
                })
                .catch(() => openNotification("error"));

        if (saved) {
            await deleteDevice();
        } else {
            await dispatch(updateProject(temporaryProject));
            await dispatch(getProject(idProject ?? ""));
            setTimeout(deleteDevice, 1000);
        }

        dispatch(updateSaved(true));
    };

    const [activeDeviceId, setDevice] = useState<number | null>(null);
    const DropdownMenu = ({ item }: { item: any }) => {
        const { id, owner } = item || {};
        const menuItems: any = [
            {
                key: "preview",
                label: t("Preview"),
                onClick: () => {
                    setDevice(id);
                    setOpenPreview(true);
                },
            },
            (owner?.user_id === userInfo?.id || isSuperAdmin) && {
                key: "edit",
                label: t("Edit"),
                onClick: () => {
                    setDevice(id);
                    setOpen(true);
                },
            },
            (owner?.user_id === userInfo?.id || isSuperAdmin) && {
                key: "delete",
                label: t("Delete"),
                onClick: () => handleDeleteClick(id),
            },
        ];
        const menu = <Menu items={menuItems} />;

        return (
            <Dropdown overlay={menu} trigger={["click"]}>
                <MoreOutlined style={{ fontSize: "16px", cursor: "pointer" }} />
            </Dropdown>
        );
    };
    const activeDevice = sideBarData?.find((device) => device?.id === activeDeviceId);
    return (
        <>
            {open && (
                <SideBarModal
                    open={open}
                    setOpen={setOpen}
                    data={activeDevice}
                />
            )}
            {openPreview && (
                <PreviewModal
                    open={openPreview}
                    setOpen={setOpenPreview}
                    data={activeDevice}
                />
            )}
            <Row
                style={{ marginTop: 8 }}
                align="middle"
                justify="space-between"
            >
                <Input
                    placeholder={t("Search by device name")}
                    prefix={<SearchOutlined />}
                    style={{ width: 280, borderRadius: 50, height: 40 }}
                    onChange={handleChangeSearch}
                    allowClear
                />
                <Button
                    type="link"
                    icon={<AddPlus />}
                    iconPosition="start"
                    style={{
                        color: Colors.MainBlue500,
                        fontSize: 16,
                        fontWeight: 700,
                        padding: 0,
                    }}
                    onClick={() => {
                        setOpen(true);
                        setDevice(null);
                    }}
                >
                    {t("custom")}
                </Button>
            </Row>

            <Row gutter={16} style={{ marginTop: 16 }}>
                <CustomSelect
                    selectedType={selectedCategory}
                    onChange={(value: number[]) => onChange(value, "category")}
                    title={t("Category")}
                    typeOptrions={categories}
                />
                <CustomSelect
                    selectedType={selectedConnection}
                    onChange={(value: number[]) =>
                        onChange(value, "connection")
                    }
                    title={t("Connection")}
                    typeOptrions={connection_types}
                />
            </Row>
            <Col style={{ marginTop: 24 }} className="scroll_alements">
                {isLoading && (
                    <Spin
                        style={{
                            marginTop: 100,
                            display: "flex",
                            justifyContent: "center",
                        }}
                    />
                )}
                {!!sideBarData?.length &&
                    !isLoading &&
                    sideBarData?.map((item: Device, index: number) => (
                        <Col
                            style={{ marginTop: index === 0 ? 0 : 24 }}
                            key={index}
                        >
                            <Row gutter={16} wrap={false}>
                                <Col
                                    style={{
                                        width: 105,
                                    }}
                                >
                                    <img
                                        src={`${baseUrl}` + item?.image}
                                        alt=""
                                        style={{
                                            cursor: "pointer",
                                            maxWidth: 80,
                                            maxHeight: 80,
                                            margin: "0 auto",
                                            display: "flex",
                                        }}
                                        onDragStart={(event) => {
                                            return onDragStart(event, item?.id);
                                        }}
                                    />
                                </Col>
                                <Col style={{ flex: 1 }}>
                                    <Flex
                                        align="center"
                                        justify="space-between"
                                    >
                                        <Flex>
                                            <Text
                                                style={{
                                                    color: Colors.MainDark300,
                                                    fontSize: 12,
                                                }}
                                            >
                                                {t("Name")}:&nbsp;
                                            </Text>
                                            <Text
                                                style={{
                                                    color: Colors.MainDark500,
                                                    fontSize: 12,
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {item?.name}
                                            </Text>
                                        </Flex>
                                        <Col
                                            style={{
                                                cursor: "pointer",
                                                width: 32,
                                            }}
                                        >
                                            {/* {item?.category_id === 5 && ( */}
                                            <DropdownMenu item={item} />
                                            {/* )} */}
                                        </Col>
                                    </Flex>
                                    <Flex style={{ margin: "8px 0" }}>
                                        <Text
                                            style={{
                                                color: Colors.MainDark300,
                                                fontSize: 12,
                                            }}
                                        >
                                            {t("Version")}:&nbsp;
                                        </Text>
                                        <Text
                                            style={{
                                                color: Colors.MainDark500,
                                                fontSize: 12,
                                                fontWeight: 700,
                                            }}
                                        >
                                            {item?.name}
                                        </Text>
                                    </Flex>
                                    <Paragraph
                                        style={{
                                            color: Colors.MainDark500,
                                            fontSize: 12,
                                            lineHeight: "18px",
                                        }}
                                    >
                                        {item?.description}
                                    </Paragraph>
                                </Col>
                            </Row>

                            <Divider
                                type="horizontal"
                                style={{
                                    margin: "16px 0",
                                    color: Colors.MainDark100,
                                }}
                            />
                        </Col>
                    ))}
                {sideBarData?.length === 0 && !isLoading && (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t("No devices")}
                    />
                )}
            </Col>
        </>
    );
};

export const CustomSelect = (props: any) => {
    const { onChange, selectedType, typeOptrions, title } = props;
    const { t } = useTranslation();

    return (
        <Col span={12}>
            <Col style={{ marginBottom: 8, padding: 0 }}>
                <Text color={Colors.MainBlue100}>{title}</Text>
            </Col>
            <SelectStyled
                mode="multiple"
                value={selectedType}
                onChange={() => {}}
                style={{ width: "100%", height: 40 }}
                placeholder={t("Category name")}
                maxTagCount={0}
                maxTagPlaceholder={(omittedValues) => {
                    if (omittedValues?.length > 1) {
                        return (
                            <span>{omittedValues?.length} items selected</span>
                        );
                    } else {
                        return transformArrayForSelect(typeOptrions)?.find(
                            (item) => item?.value === omittedValues[0]?.value
                        )?.label;
                    }
                }}
            >
                {transformArrayForSelect(typeOptrions)?.map(
                    (option: { label: string; value: number }) => (
                        <Option key={option.value} value={option.value}>
                            <Checkbox
                                checked={selectedType.includes(option?.value)}
                                style={{ fontSize: 14 }}
                                onChange={(e) => {
                                    if (e.target.checked) {
                                        onChange([
                                            ...selectedType,
                                            option?.value,
                                        ]);
                                    } else {
                                        onChange(
                                            selectedType.filter(
                                                (item: any) =>
                                                    item !== option?.value
                                            )
                                        );
                                    }
                                }}
                            >
                                {option.label}
                            </Checkbox>
                        </Option>
                    )
                )}
            </SelectStyled>
        </Col>
    );
};

interface IArrayForSelect {
    id: number;
    name: string;
}

export const transformArrayForSelect = (arr: IArrayForSelect[]) =>
    map(arr, ({ id, name }) => ({
        value: id,
        label: name,
    }));
