import { Button, Col, Flex } from "antd";
import { RotateIcon, StepBack, StepForward, TrashIcon } from "./icons";
import { HISTORY_KEY } from "./Flow";
import styled from "styled-components";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
    changeRotate,
    selectProject,
    updateEdges,
    updateNodes,
} from "store/projectSlice";

const ButtonHistory = styled(Button)`
    padding: 0;
    border: none;
    background: transparent !important;
    height: 20px;
`;

export const ControlPanel = ({
    undo,
    redo,
    index,
    clean,
}: {
    undo: () => void;
    redo: () => void;
    clean: () => void;
    index: number;
}) => {
    const dispatch = useAppDispatch();
    const history = localStorage.getItem(HISTORY_KEY) || "[]";
    const historyLength = JSON.parse(history)?.length ?? 0;

    const { rotate } = useAppSelector(selectProject);

    const handleClean = () => {
        clean();
        dispatch(updateEdges([]));
        dispatch(updateNodes([]));
    };
    const handleRotate = () => {
        dispatch(changeRotate(!rotate));
    };

    return (
        <Flex
            style={{
                //width: 380,
                height: 40,
                backgroundColor: "white",
                borderBottom: "1px solid #D2D2D7",
                //borderRight: "1px solid #D2D2D7",
                position: "absolute",
                cursor: "pointer",
                zIndex: 1000,
                top: 0,
            }}
            align="center"
        >
            <Col
                style={{
                    padding: 8,
                    marginLeft: 32,
                }}
            >
                <div onClick={handleClean}>
                    <TrashIcon />
                </div>
            </Col>
            <Col
                style={{
                    height: 40,
                    borderLeft: "1px solid #D2D2D7",
                    padding: 8,
                }}
            >
                <ButtonHistory disabled={index === 0} onClick={undo}>
                    <StepBack fill={index === 0 ? "" : "#D2D2D7"} />
                </ButtonHistory>
            </Col>
            <Col
                style={{
                    height: 40,
                    borderRight: "1px solid #D2D2D7",
                    padding: 8,
                }}
            >
                <ButtonHistory
                    onClick={redo}
                    disabled={index === historyLength}
                >
                    <StepForward
                        fill={index === historyLength ? "" : "#D2D2D7"}
                    />
                </ButtonHistory>
            </Col>

            <Col
                style={{
                    height: 40,
                    borderRight: "1px solid #D2D2D7",
                    padding: 8,
                }}
            >
                <ButtonHistory onClick={handleRotate}>
                    <RotateIcon stroke={!rotate ? "#D2D2D7" : "#5f5f5f"} />
                </ButtonHistory>
            </Col>
        </Flex>
    );
};
