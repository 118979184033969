export const ArrowRite = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
    >
        <path
            d="M9.5 5.13397C10.1667 5.51887 10.1667 6.48113 9.5 6.86603L2 11.1962C1.33333 11.5811 0.5 11.0999 0.5 10.3301V1.66987C0.5 0.900073 1.33333 0.418948 2 0.803848L9.5 5.13397Z"
            fill="#4EA5FF"
            {...rest}
        />
    </svg>
);

export const Planet = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M20 12C20 16.4183 16.4183 20 12 20M20 12C20 7.58172 16.4183 4 12 4M20 12H12H4M12 20C7.58172 20 4 16.4183 4 12M12 20V20C8.44444 15.2593 8.44444 8.74074 12 4V4M12 20V20C15.5556 15.2593 15.5556 8.74074 12 4V4M4 12C4 7.58172 7.58172 4 12 4"
            stroke="#4EA5FF"
            {...rest}
        />
    </svg>
);

export const CopyLink = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.01041 10.9896C8.20567 10.7943 8.52225 10.7943 8.71751 10.9896C8.91278 11.1849 8.91278 11.5014 8.71751 11.6967L5.53553 14.8787C4.36396 16.0503 4.36396 17.9497 5.53553 19.1213C6.70711 20.2929 8.6066 20.2929 9.77817 19.1213L12.9602 15.9393C13.1554 15.7441 13.472 15.7441 13.6673 15.9393C13.8625 16.1346 13.8625 16.4512 13.6673 16.6464L10.4853 19.8284C8.92318 21.3905 6.39052 21.3905 4.82843 19.8284C3.26633 18.2663 3.26633 15.7337 4.82843 14.1716L8.01041 10.9896ZM10.8388 8.86827C10.6436 8.67301 10.6436 8.35643 10.8388 8.16117L14.0208 4.97918C15.5829 3.41709 18.1156 3.41709 19.6777 4.97918C21.2398 6.54128 21.2398 9.07394 19.6777 10.636L16.4957 13.818C16.3004 14.0133 15.9838 14.0133 15.7886 13.818C15.5933 13.6228 15.5933 13.3062 15.7886 13.1109L18.9706 9.92893C20.1421 8.75736 20.1421 6.85786 18.9706 5.68629C17.799 4.51472 15.8995 4.51472 14.7279 5.68629L11.5459 8.86827C11.3507 9.06353 11.0341 9.06353 10.8388 8.86827ZM8.71751 15.9393C8.52225 15.7441 8.52225 15.4275 8.71751 15.2322L15.0815 8.86827C15.2767 8.67301 15.5933 8.67301 15.7886 8.86827C15.9838 9.06353 15.9838 9.38012 15.7886 9.57538L9.42462 15.9393C9.22936 16.1346 8.91278 16.1346 8.71751 15.9393Z"
            fill="#4EA5FF"
        />
    </svg>
);

export const ArrowDown = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="6"
        viewBox="0 0 8 6"
        fill="none"
    >
        <path
            d="M4.30413 5.35815C4.13809 5.54728 3.86191 5.54728 3.69587 5.35815L0.118775 1.28341C-0.13924 0.989495 0.050787 0.5 0.422902 0.5L7.5771 0.5C7.94921 0.5 8.13924 0.989495 7.88122 1.28341L4.30413 5.35815Z"
            fill="#1D1D39"
        />
    </svg>
);

export const ArrowBack = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="6"
        height="12"
        viewBox="0 0 6 12"
        fill="none"
    >
        <path
            d="M5.5 1L0.5 6L5.5 11"
            stroke="#1D1D39"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const TrashIcon = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
    >
        <path
            d="M2.88235 3.82353H4.76471H9H13.2353H15.1176L14.6471 17H3.35294L2.88235 3.82353Z"
            fill="white"
        />
        <path
            d="M2.88235 3.82353L3.35294 17H14.6471L15.1176 3.82353M2.88235 3.82353H1M2.88235 3.82353H4.76471M15.1176 3.82353H17H13.2353M15.1176 3.82353H13.2353M4.76471 3.82353H9H13.2353M4.76471 3.82353V1H13.2353V3.82353"
            stroke="#D2D2D7"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const StepBack = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M20.2446 16.0167C20.4788 17.4085 20.5042 18.5784 20.4994 19.1836C18.8782 16.402 16.9428 14.952 15.2175 14.2189C13.4232 13.4566 11.8913 13.484 11.2358 13.5465L10.7833 13.5897V14.0443V16.2989L3.56753 10.4557L10.8207 4.61137V6.82855V7.31498L11.3069 7.32836C13.9615 7.40144 16.0398 8.30871 17.5868 9.95401C19.1801 11.7234 19.9148 14.0573 20.2446 16.0167Z"
            fill="white"
            stroke="#D2D2D7"
            {...rest}
        />
    </svg>
);

export const StepForward = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            d="M3.75544 16.0167C3.52121 17.4085 3.49583 18.5784 3.5006 19.1836C5.12183 16.402 7.05724 14.952 8.78253 14.2189C10.5768 13.4566 12.1087 13.484 12.7642 13.5465L13.2167 13.5897V14.0443V16.2989L20.4325 10.4557L13.1793 4.61137V6.82855V7.31498L12.6931 7.32836C10.0385 7.40144 7.96017 8.30871 6.41321 9.95401C4.8199 11.7234 4.08522 14.0573 3.75544 16.0167Z"
            fill="white"
            stroke="#D2D2D7"
            {...rest}
        />
    </svg>
);

export const EditIcon = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
    >
        <path d="M4 7H20" stroke="#1D1D39" strokeLinecap="round" />
        <circle cx="16" cy="7" r="2" fill="white" stroke="#1D1D39" />
        <path d="M4 12H20" stroke="#1D1D39" strokeLinecap="round" />
        <circle cx="8" cy="12" r="2" fill="white" stroke="#1D1D39" />
        <path d="M4 17H20" stroke="#1D1D39" strokeLinecap="round" />
        <circle cx="16" cy="17" r="2" fill="white" stroke="#1D1D39" />
    </svg>
);

export const MoveIcon = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 9C10.5523 9 11 8.55228 11 8C11 7.44772 10.5523 7 10 7C9.44772 7 9 7.44772 9 8C9 8.55228 9.44772 9 10 9ZM14 9C14.5523 9 15 8.55228 15 8C15 7.44772 14.5523 7 14 7C13.4477 7 13 7.44772 13 8C13 8.55228 13.4477 9 14 9ZM14 13C14.5523 13 15 12.5523 15 12C15 11.4477 14.5523 11 14 11C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13ZM15 16C15 16.5523 14.5523 17 14 17C13.4477 17 13 16.5523 13 16C13 15.4477 13.4477 15 14 15C14.5523 15 15 15.4477 15 16ZM11 12C11 12.5523 10.5523 13 10 13C9.44772 13 9 12.5523 9 12C9 11.4477 9.44772 11 10 11C10.5523 11 11 11.4477 11 12ZM10 17C10.5523 17 11 16.5523 11 16C11 15.4477 10.5523 15 10 15C9.44772 15 9 15.4477 9 16C9 16.5523 9.44772 17 10 17Z"
            fill="#1D1D39"
            {...rest}
        />
    </svg>
);

export const DeleteIcon = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        {...rest}
    >
        <path
            d="M2.88235 3.82353H4.76471H9H13.2353H15.1176L14.6471 17H3.35294L2.88235 3.82353Z"
            fill="white"
        />
        <path
            d="M2.88235 3.82353L3.35294 17H14.6471L15.1176 3.82353M2.88235 3.82353H1M2.88235 3.82353H4.76471M15.1176 3.82353H17H13.2353M15.1176 3.82353H13.2353M4.76471 3.82353H9H13.2353M4.76471 3.82353V1H13.2353V3.82353"
            stroke="#1D1D39"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const StopIcon = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        {...rest}
    >
        <rect
            x="4.5"
            y="4.5"
            width="15"
            height="15"
            rx="7.5"
            fill="white"
            stroke="black"
        />
        <rect x="9" y="9" width="2" height="6" rx="1" fill="#1D1D39" />
        <rect x="13" y="9" width="2" height="6" rx="1" fill="#1D1D39" />
    </svg>
);

export const PlayIcon = ({ ...rest }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
    >
        <path
            d="M9.5 5.13397C10.1667 5.51887 10.1667 6.48113 9.5 6.86603L2 11.1962C1.33333 11.5811 0.5 11.0999 0.5 10.3301V1.66987C0.5 0.900073 1.33333 0.418948 2 0.803848L9.5 5.13397Z"
            fill="#1D1D39"
            {...rest}
        />
    </svg>
);

export const RotateIcon = ({ ...rest }) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M11.5 20.5C6.80558 20.5 3 16.6944 3 12C3 7.30558 6.80558 3.5 11.5 3.5C16.1944 3.5 20 7.30558 20 12C20 13.5433 19.5887 14.9905 18.8698 16.238M22.5 15L18.8698 16.238M17.1747 12.3832L18.5289 16.3542L18.8698 16.238"
            stroke="#D2D2D7"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            {...rest}
        />
    </svg>
);
