import { Col, Flex, Form, TimePicker, Typography } from "antd";
import { EmptyData } from "./SequenceModal";
import { FormItem } from "shared/components";
import { CustomSelect } from "../side-bar/DevicesPanel";
import { useContext, useEffect, useState } from "react";
import { selectProject } from "store/projectSlice";
import { useAppSelector } from "store/hooks";
import moment from "moment";
import { SequenceContext } from "./SequenceList";

const { Text, Title } = Typography;

export const ListSettings = (props: any) => {
    const [form] = Form.useForm();
    const { activeCard, open } = props;
    const { sequence } = useContext(SequenceContext);
    const activeData = sequence?.find((item: any) => item.id === activeCard);

    const isWait = activeData?.text === "Wait";
    const isIF = activeData?.text === "If";
    const isDevice = activeData?.type === "device";
    const isGoTo = activeData?.text === "Go to";

    return (
        <Col
            style={{
                height: "100%",
            }}
        >
            <Title level={5} style={{ fontWeight: 700, marginBottom: 16 }}>
                List:
            </Title>
            {!sequence?.length && <EmptyData />}
            {!!activeCard && (
                <Form
                    name="condition"
                    onFinish={() => {}}
                    style={{ marginTop: 16 }}
                    form={form}
                >
                    {isDevice && (
                        <DeviceCondition
                            data={activeData}
                            open={open}
                            activeCard={activeCard}
                        />
                    )}
                    {isWait && <WaitCondition data={activeData} />}
                    {isGoTo && <GotoCondition data={activeData} />}
                </Form>
            )}
        </Col>
    );
};

const WaitCondition = ({ data }: any) => {
    const { setSequence } = useContext(SequenceContext);
    const [selectedTime, setSelectedTime] = useState(moment("00:00", "mm:ss"));

    useEffect(() => {
        if (data?.timer) {
            const minutes = Math.floor(data.timer / 60);
            const seconds = data.timer % 60;
            setSelectedTime(moment(`${minutes}:${seconds}`, "mm:ss"));
        }
    }, [data]);

    const handleOk = (time: any) => {
        if (time) {
            const minutes = time.minutes();
            const seconds = time.seconds();
            const total = minutes * 60 + seconds;
            setSequence((prevData: any) =>
                prevData.map((item: any) =>
                    item?.id === data.id
                        ? {
                              ...item,
                              timer: Number(total),
                              typeCondition: "wait",
                          }
                        : item
                )
            );
        }
    };

    const handleChange = (time: any) => {
        setSelectedTime(time);
    };

    const format = "mm:ss";
    return (
        <Flex vertical>
            <Text>Timer:</Text>
            <TimePicker
                format={format}
                className="timePicker"
                onOk={handleOk}
                value={selectedTime as any}
                onChange={handleChange}
            />
        </Flex>
    );
};

const DeviceCondition = ({ data, open, activeCard }: any) => {
    const { temporaryProject } = useAppSelector(selectProject);
    const { nodes, edges } = temporaryProject?.template || {};
    const { id, outputId } = data || {};
    const [selectedChanel, setSelectedChanel] = useState<any[]>([]);
    const activeDevice = nodes?.find(
        (item: any) => item?.id === data?.deviceId
    );
    const activeConnectors = activeDevice?.node?.connectors?.filter(
        (item: any) => item?.type === "target"
    );
    const { sequence, setSequence } = useContext(SequenceContext);

    useEffect(() => {
        setSelectedChanel(outputId || []);
    }, [id, open, activeCard]);

    const onChange = (value: number[]) => {
        const newChannels = value.map((val) => ({
            id: val,
            active: true,
        }));
        setSelectedChanel(newChannels);

        const names = options
            .filter((channel: any) => value.includes(channel.id))
            .map((channel: any) => channel.name)
            .join(", ");

        setSequence((prevData: any) =>
            prevData.map((item: any) =>
                item?.id === data?.id
                    ? {
                          ...item,
                          outputLabel: names,
                          outputId: newChannels,
                          showDevideInChoose:
                              connectedPneumaticValves?.length > 0
                                  ? connectedPneumaticValves?.length
                                  : [1],
                      }
                    : item
            )
        );
    };

    const pneumaticValve = activeConnectors?.filter(
        (item: any) =>
            item?.connection_type_id === 1 || item?.connection_type_id === 2
    );
    const connectedPneumaticValves = pneumaticValve?.filter((item: any) =>
        edges.some((edge: any) => +edge.sourceHandle === +item.id)
    );

    const options = connectedPneumaticValves?.map(
        (item: any, index: number) => ({
            name: `Chanel ${index + 1} - ${item?.connection_type?.name} - ${
                item?.position
            }`,
            id: item?.id,
        })
    );

    const handleChannelStatusChange = (channelId: number) => {
        setSelectedChanel((prev) =>
            prev.map((channel) =>
                channel.id === channelId
                    ? { ...channel, active: !channel.active }
                    : channel
            )
        );
        setSequence((prevData: any) =>
            prevData.map((item: any) =>
                item.id === data.id
                    ? {
                          ...item,
                          outputId: item.outputId.map((output: any) =>
                              output.id === channelId
                                  ? { ...output, active: !output.active }
                                  : output
                          ),
                      }
                    : item
            )
        );
    };

    return (
        <Flex style={{ width: "100%" }} vertical>
            <Text style={{ marginBottom: 16, fontSize: 16 }}>
                Device: {activeDevice?.data?.label}
            </Text>
            <Col span="24">
                <CustomSelect
                    selectedType={selectedChanel.map((ch) => ch.id)}
                    onChange={(value: number[]) => onChange(value)}
                    title="Chanel"
                    typeOptrions={options}
                />
            </Col>
            <Col span="24" style={{ marginTop: 16 }}>
                {selectedChanel?.map((option: any) => {
                    const label =
                        options.find((channel: any) => channel.id === option.id)
                            ?.name || "";
                    return (
                        <div
                            key={option.id}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                marginBottom: 8,
                            }}
                        >
                            <Text style={{ marginRight: 8 }}>{label}</Text>
                            <label
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    cursor: "pointer",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    checked={option?.active || false}
                                    onChange={() =>
                                        handleChannelStatusChange(option.id)
                                    }
                                    style={{
                                        marginRight: 8,
                                        width: 16,
                                        height: 16,
                                    }}
                                />
                                {option?.active ? "Active" : "Inactive"}
                            </label>
                        </div>
                    );
                })}
            </Col>
        </Flex>
    );
};

const GotoCondition = ({ data }: any) => {
    const { project } = useAppSelector(selectProject);
    const { nodes } = project?.template || {};
    const [selectedChanel, setSelectedChanel] = useState<number[]>([]);
    const activeDevice = nodes?.find(
        (item: any) => item?.id === data?.deviceId
    );
    const activeConnectors = activeDevice?.node?.connectors?.filter(
        (item: any) => item?.type === "target"
    );
    const { setSequence } = useContext(SequenceContext);

    const onChange = (value: any) => {
        const names = options
            .filter((channel: any) => value.includes(channel.id))
            .map((channel: any) => channel.name)
            .join(", ");

        setSelectedChanel(value);
        setSequence((prevData: any) =>
            prevData.map((item: any) =>
                item?.id === data?.id
                    ? {
                          ...item,
                          outputLabel: names,
                          outputId: value,
                      }
                    : { ...item, label: item.label }
            )
        );
    };

    const onChangeLabel = (id: number, newLabel: string) => {
        setSequence((prevData: any) =>
            prevData.map((item: any) =>
                item?.id === id ? { ...item, label: newLabel } : item
            )
        );
    };

    const options = activeConnectors?.map((item: any, index: number) => {
        return {
            name: `Chanel ${index + 1}`,
            id: item?.id,
        };
    });

    return (
        <Flex style={{ width: "100%" }} vertical>
            <FormItem
                label="Instrument"
                placeholder="Instrument"
                formItemProps={{
                    name: `label_${data?.id}`,
                }}
                inputProps={{
                    defaultValue: data?.label,
                    onChange: (e) => onChangeLabel(data?.id, e.target.value),
                }}
            />
            <Col span="24">
                <CustomSelect
                    selectedType={selectedChanel}
                    onChange={(value: number[]) => onChange(value)}
                    title="Chanel"
                    typeOptrions={options}
                />
            </Col>
        </Flex>
    );
};
