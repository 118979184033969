export const AddPlus = ({ ...rest }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="12" cy="12" r="10" fill="#4EA5FF" />
      <path
        d="M7 12H12M17 12H12M12 12V17M12 12V7"
        stroke="white"
        strokeLinecap="round"
      />
    </svg>
  );
};
