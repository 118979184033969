import { Col, Flex, Form, Modal, Row, Typography } from "antd";
import { Colors } from "constants/colors";
import {
    ButtonApprove,
    ButtonTransparent,
    ErrorText,
    FormItem,
} from "shared/components";
import { ListSettings } from "./ListSettings";
import { ListChoosed } from "./ListChoosed";
import { useContext, useEffect, useState } from "react";
import { DevicesAndConditions } from "./DevicesAndConditions";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { validationErrorMessages } from "constants/form";
import { useParams } from "react-router-dom";
import { getData } from "store/sequencesSlice";
import { useAppDispatch } from "store/hooks";
import { SequenceContext } from "./SequenceList";

const { Title } = Typography;

const { required } = validationErrorMessages;

export const SequenceModal = ({
    open,
    setOpen,
    data,
}: {
    open: boolean;
    setOpen: (item: boolean) => void;
    data: any;
}) => {
    const dispatch = useAppDispatch();
    const { sequence, setSequence } = useContext(SequenceContext);

    const handleCancel = () => {
        setOpen(false);
        setSequence([]);
        form.resetFields();
        setActiveCondition(null);
    };
    const { id } = useParams();

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [activeCondition, setActiveCondition] = useState<number | null>();

    const [play, setPlay] = useState(false);

    useEffect(() => {
        if (data) {
            setSequence(data?.parameters);
            form.setFieldValue("name", data?.name);
        }
    }, [data, open]);

    const onFinish = async (values: any) => {
        setLoading(true);
        setError(null);

        const apiMethod = data ? axios.put : axios.post;
        const url = data
            ? `${ENDPOINTS.sequences()}/${data.id}`
            : ENDPOINTS.sequences();

        await apiMethod(url, {
            name: values?.name,
            parameters: sequence,
            project_id: id,
        })
            .then(() => {
                dispatch(getData(id));
                handleCancel();
            })
            .catch((e) => setError(e?.response?.data?.message || "Error"))
            .finally(() => setLoading(false));
    };

    return (
        <Modal
            open={open}
            title={
                <Title level={3} style={{ fontWeight: 800, marginBottom: 24 }}>
                    Sequence edit panel
                </Title>
            }
            onCancel={handleCancel}
            footer={null}
            width={"100%"}
        >
            <Form name="add_sequence" onFinish={onFinish} form={form}>
                <Flex>
                    <Col>
                        <FormItem
                            placeholder="Sequence name"
                            formItemProps={{
                                name: "name",
                                rules: [{ required: true, message: required }],
                            }}
                            inputProps={{
                                maxLength: 30,
                            }}
                        />
                    </Col>
                    <Col style={{ marginLeft: "24px" }}>
                        {!play ? (
                            <PlayIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setPlay(true)}
                            />
                        ) : (
                            <StopIcon
                                style={{ cursor: "pointer" }}
                                onClick={() => setPlay(false)}
                            />
                        )}
                    </Col>
                </Flex>

                <Row>
                    <Col span={4}>
                        <DevicesAndConditions play={play} />
                    </Col>
                    <Col span={12}>
                        <ListChoosed
                            activeCard={activeCondition}
                            setActiveCard={setActiveCondition}
                            play={play}
                            setPlay={setPlay}
                        />
                    </Col>
                    <Col span={8}>
                        <ListSettings
                            activeCard={activeCondition}
                            open={open}
                        />
                    </Col>
                </Row>

                <Flex justify="end">
                    <Col span={8}>
                        <Flex justify="space-between" gap={16}>
                            <ButtonTransparent
                                handle={handleCancel}
                                width={"100%"}
                            />
                            <ButtonApprove
                                text="Save changes"
                                loading={loading}
                                width={"100%"}
                            />
                        </Flex>
                        <ErrorText
                            text={error || ""}
                            style={{ marginTop: 16, marginBottom: 0 }}
                        />
                    </Col>
                </Flex>
            </Form>
        </Modal>
    );
};

export const EmptyData = () => (
    <Flex vertical align="center" style={{ height: "100%" }} justify="center">
        <Title
            level={5}
            style={{
                fontWeight: 700,
                color: Colors.MainDark300,
            }}
        >
            No devices and conditions yet...
        </Title>
        <Title level={5} style={{ color: Colors.MainDark300, marginTop: 0 }}>
            You will see the list after putting device or condition card this
            panel
        </Title>
    </Flex>
);

const PlayIcon = ({ ...rest }: any) => (
    <svg
        fill="#6B8899"
        height="40px"
        width="40px"
        version="1.1"
        id="Capa_1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-4.8 -4.8 69.60 69.60"
        stroke="#6B8899"
        stroke-width="3.4199999999999995"
        {...rest}
    >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
            <g>
                <path d="M45.563,29.174l-22-15c-0.307-0.208-0.703-0.231-1.031-0.058C22.205,14.289,22,14.629,22,15v30 c0,0.371,0.205,0.711,0.533,0.884C22.679,45.962,22.84,46,23,46c0.197,0,0.394-0.059,0.563-0.174l22-15 C45.836,30.64,46,30.331,46,30S45.836,29.36,45.563,29.174z M24,43.107V16.893L43.225,30L24,43.107z" />
                <path d="M30,0C13.458,0,0,13.458,0,30s13.458,30,30,30s30-13.458,30-30S46.542,0,30,0z M30,58C14.561,58,2,45.439,2,30 S14.561,2,30,2s28,12.561,28,28S45.439,58,30,58z" />
            </g>
        </g>
    </svg>
);

const StopIcon = ({ ...rest }: any) => (
    <svg
        width="40px"
        height="40px"
        viewBox="0 0 24.00 24.00"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        stroke="#6B8899"
        strokeWidth="0.00024000000000000003"
        {...rest}
    >
        <g id="SVGRepo_bgCarrier" stroke-width="0" />
        <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g id="SVGRepo_iconCarrier">
            <path d="M9 9H11V15H9V9Z" fill="#6B8899" />
            <path d="M15 15H13V9H15V15Z" fill="#6B8899" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z"
                fill="#6B8899"
            />
        </g>
    </svg>
);
