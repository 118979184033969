import { Colors } from "constants/colors";
import { Avatar, Card, Col, Flex, Typography } from "antd";
import { ReactFlow, ReactFlowProvider } from "@xyflow/react";
import { NavLink, useNavigate } from "react-router-dom";
import {
    PencilIcon,
    FavoriteTrue,
    FavoriteFalse,
    EyeVisible,
    EyeInvisible,
} from "./icons";
import { Routes } from "constants/routes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { updateFavourites } from "store/projectSlice";
import {
    ButtonStyle,
    customNodes,
    DropDownCardMenu,
    openNotification,
} from "shared/components";
import styled from "styled-components";
import { Project, ROLES, TypeDropDown } from "shared/interfaces";
import { useRef, useState } from "react";
import { getFavourites, getTemplates } from "store/dashboardSlice";
import { HISTORY_INDEX_KEY, HISTORY_KEY } from "pages/project/Flow";
import { selectUser } from "store/userSlice";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { updateFilters, updateSorter } from "store/appliancesSlice";

const { Title, Text } = Typography;

export const ProjectCard = ({ data }: { data: Project }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const reactFlowWrapper = useRef<any>(null);
    const {
        name,
        updated_at,
        template,
        id,
        state_template,
        publish,
        is_favorite,
    } = data || {};
    const [favoriteStatus, setFavoriteStatus] = useState(is_favorite);
    const { edges, nodes } = template || {};
    const { roles } = useAppSelector(selectUser);
    const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
    const isAdmin = roles.includes(ROLES.ADMIN);
    const isUser = roles.includes(ROLES.USER);

    const handleFavorite = (event: any) => {
        event.stopPropagation();
        dispatch(updateFavourites({ favorite: +!favoriteStatus, id: id })).then(
            () => {
                setFavoriteStatus(!favoriteStatus);
                dispatch(getFavourites());
            }
        );
    };
    const handleOpen = (event: any) => {
        event.stopPropagation();
        navigate(`/${Routes.project}/${id}`);
        localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
        localStorage.setItem(HISTORY_INDEX_KEY, "0");
        dispatch(updateFilters({ searchText: "" }));
        dispatch(updateSorter(""));
    };
    const handleClick = (event: any) => {
        event.stopPropagation();
        localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
        localStorage.setItem(HISTORY_INDEX_KEY, "0");
        dispatch(updateFilters({ searchText: "" }));
        dispatch(updateSorter(""));
    };

    const handlePublish = async (event: any) => {
        event.stopPropagation();
        await axios
            .put(ENDPOINTS.projectById(id), {
                publish: publish === 1 ? 0 : 1,
            })
            .then(() => {
                openNotification("success");
                setTimeout(() => dispatch(getTemplates()), 1000);
            })
            .catch((e) => {
                openNotification("error");
            });
    };

    return (
        <Col
            lg={8}
            md={12}
            sm={12}
            style={{ marginTop: 16 }}
            className="projectCard"
        >
            <CardImageStyled>
                <div className="providerflow" style={{ height: "100%" }}>
                    <ReactFlowProvider>
                        <div
                            className="reactflow-wrapper"
                            ref={reactFlowWrapper}
                        >
                            <ReactFlow
                                nodes={nodes}
                                edges={edges}
                                nodeTypes={customNodes(nodes)}
                                fitView
                                maxZoom={1}
                                zoomOnScroll={false}
                            />
                        </div>
                    </ReactFlowProvider>
                </div>
                <Flex
                    justify="space-between"
                    style={{
                        padding: 8,
                        position: "absolute",
                        top: 0,
                        width: "100%",
                        height: "100%",
                        zIndex: 1,
                    }}
                    onClick={handleOpen}
                >
                    {isSuperAdmin || isAdmin || (!state_template && isUser) ? (
                        <NavLink
                            to={`/${Routes.project}/edit/${data?.id}`}
                            style={{ height: "fit-content" }}
                            onClick={handleClick}
                        >
                            <ButtonStyle
                                style={{
                                    backgroundColor: Colors.MainBlue500,
                                }}
                                className="edit_btn"
                            >
                                <PencilIcon />
                            </ButtonStyle>
                        </NavLink>
                    ) : (
                        <div />
                    )}
                    <Flex
                        style={{ height: "fit-content" }}
                        onClick={handleClick}
                        gap={8}
                    >
                        <ButtonStyle
                            className={!!favoriteStatus ? "" : "edit_btn"}
                            onClick={handleFavorite}
                        >
                            {!!favoriteStatus ? (
                                <FavoriteTrue />
                            ) : (
                                <FavoriteFalse />
                            )}
                        </ButtonStyle>

                        {!!state_template && (isSuperAdmin || isAdmin) && (
                            <ButtonStyle onClick={handlePublish}>
                                {publish === 1 ? (
                                    <EyeVisible />
                                ) : (
                                    <EyeInvisible />
                                )}
                            </ButtonStyle>
                        )}
                        {(isSuperAdmin ||
                            isAdmin ||
                            (!state_template && isUser)) && (
                            <DropDownCardMenu
                                data={data}
                                type={TypeDropDown.DASHBOARD}
                                reactFlowWrapper={reactFlowWrapper}
                                favoriteStatus={favoriteStatus}
                                setFavoriteStatus={setFavoriteStatus}
                            />
                        )}
                    </Flex>
                </Flex>
            </CardImageStyled>
            <Title
                style={{
                    margin: "8px 0 4px",
                    fontSize: 12,
                    fontWeight: 700,
                    color: Colors.MainDark500,
                }}
            >
                {name}
            </Title>
            <Text style={{ fontSize: 10, color: Colors.MainDark300 }}>
                {timeAgo(updated_at)}
            </Text>
        </Col>
    );
};

interface ILastProjectCard {
    title: string;
    description: string;
    image?: string;
}
export const LastProjectCard = ({
    title,
    description,
    image,
}: ILastProjectCard) => (
    <Card
        style={{
            padding: 16,
            width: 300,
            backgroundColor: Colors.MainDark50,
            marginTop: 24,
        }}
    >
        <Flex>
            <Avatar size={32} src={image} />
            <Flex vertical style={{ marginLeft: 16 }}>
                <Title
                    style={{ marginBottom: 4, fontSize: 12, fontWeight: 700 }}
                >
                    {title}
                </Title>
                <Text style={{ fontSize: 10, color: Colors.MainDark300 }}>
                    {description}
                </Text>
            </Flex>
        </Flex>
    </Card>
);

export function timeAgo(dateString: string) {
    const givenDate = new Date(dateString);
    const now = new Date();
    const diffInSeconds = Math.floor((Number(now) - Number(givenDate)) / 1000);

    const units = [
        { name: "year", seconds: 31536000 },
        { name: "month", seconds: 2592000 },
        { name: "week", seconds: 604800 },
        { name: "day", seconds: 86400 },
        { name: "hour", seconds: 3600 },
        { name: "minute", seconds: 60 },
        { name: "second", seconds: 1 },
    ];

    for (let unit of units) {
        const count = Math.floor(diffInSeconds / unit.seconds);
        if (count > 0) {
            return `Edited ${count} ${unit.name}${count > 1 ? "s" : ""} ago`;
        }
    }
    return "Edited just now";
}

const CardImageStyled = styled(Col)`
    width: 100%;
    height: 216px;
    border-radius: 8px;
    background-color: ${Colors.MainDark50};
    position: relative;
    margin-bottom: 8px;
    padding: 0 !important;
    .edit_btn {
        opacity: 0;
    }
    &:hover .edit_btn {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.3s ease-in-out;
    }
`;
