import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { RootState } from "./store";
import { ConnectionType, Device, IStore } from "shared/interfaces";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";

const initialState: IStore<Device> & {
    connection_types: ConnectionType[];
    categories: ConnectionType[];
} = {
    data: [],
    sideBarData: [],
    fetchStatus: "init",
    pagination: {
        currentPage: 1,
        total: 1,
        perPage: 100,
    },
    sorter: {},
    filters: {},
    fetchError: null,
    connection_types: [],
    categories: [],
};

export const updateDevice = createAsyncThunk(
    "device/updateUser",
    async (values: { id: number; data: Device }, { rejectWithValue }) => {
        const { id, data } = values;
        try {
            const req = await axios.put(ENDPOINTS.deviceById(id), data);
            return req;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const getData = createAsyncThunk(
    "device/Data",
    async (_, { getState, rejectWithValue }) => {
        const state = getState() as RootState;
        const { sorter, filters } = state.appliances;
        const queryParams = {
            search: filters?.searchText,
            category_id: sorter.categories,
            connection_type_id: sorter.connection_types,
        };

        try {
            const res = await axios.get(ENDPOINTS.devices(), {
                params: queryParams,
                headers: {
                    "Content-Type": "application/json",
                },
            });
            return res;
        } catch (e) {
            return rejectWithValue(e);
        }
    }
);

export const appliancesSlice = createSlice({
    name: "device",
    initialState,
    reducers: {
        updateSorter: (state, action) => {
            state.sorter = action?.payload;
        },
        updateFilters: (state, action) => {
            state.filters = action?.payload;
        },
        updateData: (state, action) => {
            state.data = action?.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getData.pending, (state) => {
                state.fetchStatus = "fetching";
            })
            .addCase(getData.fulfilled, (state, { payload }) => {
                const { data } = payload || {};
                state.fetchStatus = "success";
                const plainFilters = { ...state.filters };
                const plainSorter = { ...state.sorter };
                const isEmptySorter =
                    Object.keys(plainSorter?.categories || {}).length === 0 &&
                    Object.keys(plainSorter?.connection_types || {}).length ===
                        0;
                if (!plainFilters?.searchText && isEmptySorter) {
                    state.data = data.devices;
                }
                state.sideBarData = data.devices;
                state.connection_types = data.connection_types;
                state.categories = data.categories;
            })
            .addCase(getData.rejected, (state, { error }) => {
                state.fetchStatus = "error";
                //@ts-ignore
                state.fetchError = error;
            });
    },
});

export const { updateSorter, updateFilters, updateData } =
    appliancesSlice.actions;

export const selectAppliances = (state: RootState) => state.appliances;

export default appliancesSlice.reducer;
