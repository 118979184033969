import { Col, Flex, Row, Select, Spin, Typography } from "antd";
import { Routes } from "constants/routes";
import { Colors } from "constants/colors";
import { useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import {
    ButtonApprove,
    ButtonStyle,
    ButtonTransparent,
    customNodes,
    DropDownCardMenu,
    TypeConnections,
} from "shared/components";
import {
    ReactFlow,
    ReactFlowProvider,
    Controls,
    useNodesState,
    useEdgesState,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import { ArrowRite, ArrowBack } from "pages/project/icons";
import { FavoriteFalse, FavoriteTrue } from "pages/dashboard/icons";
import {
    getProject,
    getProjectByToken,
    selectProject,
    updateFavourites,
} from "store/projectSlice";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useSelector } from "react-redux";
import { ROLES, TypeDropDown } from "shared/interfaces";
import { selectUser } from "store/userSlice";
import {
    ConnectType,
    HISTORY_INDEX_KEY,
    HISTORY_KEY,
} from "pages/project/Flow";
import { ContactSales } from "./ContactSales";
import useWindowWidth from "shared/hooks/useWindowWidth ";
import { useTranslation } from "react-i18next";
import { updateFilters, updateSorter } from "store/appliancesSlice";
import { getData, selectSequences } from "store/sequencesSlice";
const { Option } = Select;

const { Title, Text } = Typography;

export const ProjectPreview = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [nodes, setNodes] = useNodesState([]);
    const [edges, setEdges] = useEdgesState([]);
    const [simulation, setSimulation] = useState(false);
    const { project, fetchStatus } = useSelector(selectProject);
    const loading = fetchStatus === "init" || fetchStatus === "fetching";
    const { name, is_favorite, state_template } = project || {};
    const reactFlowWrapper = useRef<any>(null);
    const { isAuthorized, userInfo } = useAppSelector(selectUser);
    const urlParams = new URLSearchParams(window.location.search);
    const tokenValue = urlParams.get("token");
    const { roles } = useAppSelector(selectUser);
    const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
    const isAdmin = roles.includes(ROLES.ADMIN);
    const isUser = roles.includes(ROLES.USER);
    const windowWidth = useWindowWidth();
    const { t } = useTranslation();
    const { data } = useAppSelector(selectSequences);
    const [selectedAnimation, setSelectedAnimation] = useState(null);

    useEffect(() => {
        if (tokenValue || id) {
            dispatch(getData(id));
            dispatch(
                tokenValue
                    ? getProjectByToken(tokenValue)
                    : getProject(id as string)
            ).then((item: any) => {
                const { nodes = [], edges = [] } =
                    item?.payload?.data?.template || {};
                setNodes(nodes);
                setEdges(edges);
            });
        }
    }, [id, tokenValue]);

    const editProject = () => {
        navigate(`/${Routes.project}/edit/${project?.id}`);
        localStorage.setItem(HISTORY_KEY, JSON.stringify([]));
        localStorage.setItem(HISTORY_INDEX_KEY, "0");
        dispatch(updateFilters({ searchText: "" }));
        dispatch(updateSorter(""));
    };

    const handleSimulation = () => {
        setSimulation((prev) => !prev);
    };

    const processIdRef = useRef(0); // Tracks the current process ID

    useEffect(() => {
        const isAnimated = (type: ConnectType) =>
            type === ConnectType.LIQUID || type === ConnectType.PNEUMATIC;

        const stopAnimation = () => {
            processIdRef.current += 1; // Increment process ID to invalidate previous operations
            setEdges((eds: any) =>
                eds.map((edge: any) => ({
                    ...edge,
                    animated: false,
                }))
            );
            return;
        };

        if (!simulation) {
            setEdges((eds: any) =>
                eds.map((edge: any) => ({
                    ...edge,
                    animated: false,
                }))
            );
            return;
        }

        const activeSequences =
            data?.find((item: any) => item?.id === selectedAnimation)
                ?.parameters || [];

        if (!activeSequences?.length) {
            setEdges((eds: any) =>
                eds.map((edge: any) => ({
                    ...edge,
                    animated: isAnimated(edge.style.stroke)
                        ? simulation
                        : false,
                }))
            );
        } else {
            const animateEdge = (dependency: any, processId: number) => {
                return new Promise<void>((resolve) => {
                    if (processIdRef.current !== processId) return resolve();
                    setEdges((eds: any) =>
                        eds.map((edge: any) => {
                            const matchingOutput = dependency?.outputId.find(
                                (output: any) =>
                                    output.id === +edge.sourceHandle &&
                                    edge?.source === dependency?.deviceId
                            );
                            return matchingOutput
                                ? { ...edge, animated: matchingOutput?.active }
                                : edge;
                        })
                    );
                    resolve();
                });
            };

            const delay = (ms: number, processId: number) =>
                new Promise<void>((resolve) => {
                    const timeout = setTimeout(() => {
                        if (processIdRef.current === processId) resolve();
                        clearTimeout(timeout); // Cleanup to avoid memory leaks
                    }, ms * 1000);
                });

            const animateDependencies = async (processId: number) => {
                for (const dependency of activeSequences) {
                    if (processIdRef.current !== processId) break; // Stop the loop if invalid
                    if (dependency.type === "device") {
                        await animateEdge(dependency, processId);
                    } else if (
                        dependency.type === "condition" &&
                        dependency.typeCondition === "wait"
                    ) {
                        await delay(dependency.timer, processId);
                    }
                }
            };

            // Start a new animation process
            const newProcessId = ++processIdRef.current; // Increment to mark a new process
            if (activeSequences.length > 0) {
                animateDependencies(newProcessId);
            } else {
                stopAnimation();
            }
        }

        return () => {
            processIdRef.current += 1; // Invalidate the current process on cleanup
        };
    }, [simulation]);

    const handleFavorite = () => {
        if (id) {
            dispatch(
                updateFavourites({ favorite: +!is_favorite, id: +id })
            ).then(() => {
                setTimeout(() => dispatch(getProject(id)), 1000);
            });
        }
    };

    const [openContact, setOpenContact] = useState(false);
    const contactSales = () => {
        setOpenContact(true);
    };

    const nodeHandlers = useMemo(() => customNodes(nodes), [nodes]);

    const handleChange = (value: any) => {
        setSelectedAnimation(value);
        setSimulation(false);
    };

    if (windowWidth < 600) {
        return (
            <>
                <ContactSales open={openContact} setOpen={setOpenContact} />
                <Flex
                    style={{
                        width: "100%",
                        height: "100%",
                        paddingTop: "64px",
                    }}
                    vertical
                >
                    {loading && !project?.id && (
                        <Spin
                            style={{
                                margin: "auto",
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "100px",
                            }}
                        />
                    )}
                    {project?.id && (
                        <>
                            <Col style={{ padding: " 24px 16px" }}>
                                <Flex align="center" justify="space-between">
                                    <Flex align="center">
                                        <NavLink to={`/${Routes.base}`}>
                                            <ArrowBack />
                                        </NavLink>
                                        <Title
                                            level={3}
                                            style={{
                                                fontWeight: 800,
                                                margin: 0,
                                                marginLeft: 16,
                                            }}
                                        >
                                            {name}
                                        </Title>
                                    </Flex>
                                    {isAuthorized && (
                                        <Flex>
                                            <ButtonStyle
                                                onClick={handleFavorite}
                                                style={{
                                                    backgroundColor:
                                                        Colors.MainDark50,
                                                    marginRight: 8,
                                                }}
                                            >
                                                {is_favorite ? (
                                                    <FavoriteTrue />
                                                ) : (
                                                    <FavoriteFalse />
                                                )}
                                            </ButtonStyle>
                                            <DropDownCardMenu
                                                data={project}
                                                type={TypeDropDown.VIEW}
                                                reactFlowWrapper={
                                                    reactFlowWrapper
                                                }
                                            />
                                        </Flex>
                                    )}
                                </Flex>
                            </Col>
                            <Col style={{ width: "100%" }}>
                                <Col
                                    style={{
                                        background: Colors.MainDark50,
                                        height: "100%",
                                    }}
                                >
                                    <Select
                                        style={{ width: 300 }}
                                        placeholder="Select simulation"
                                        onChange={handleChange}
                                    >
                                        {data?.map((device: any) => (
                                            <Option
                                                key={device.id}
                                                value={device.id}
                                            >
                                                {device.name}
                                            </Option>
                                        ))}
                                    </Select>
                                    <ButtonTransparent
                                        text={
                                            !simulation
                                                ? t("Start simulation")
                                                : t("Stop simulation")
                                        }
                                        handle={handleSimulation}
                                        style={{
                                            position: "absolute",
                                            bottom: 16,
                                            right: 16,
                                            marginTop: 0,
                                            zIndex: 100,
                                        }}
                                        iconPosition={"end"}
                                        icon={
                                            !simulation ? (
                                                <ArrowRite />
                                            ) : (
                                                <div className="rounded-triangle" />
                                            )
                                        }
                                    />
                                    <div
                                        className="providerflow"
                                        style={{
                                            height: "calc(100vh - 278px)",
                                        }}
                                    >
                                        <ReactFlowProvider>
                                            <div
                                                className="reactflow-wrapper"
                                                ref={reactFlowWrapper}
                                            >
                                                <ReactFlow
                                                    nodes={nodes}
                                                    edges={edges}
                                                    nodeTypes={customNodes(
                                                        nodes
                                                    )}
                                                    fitView
                                                    maxZoom={2.5}
                                                    nodesConnectable={false}
                                                />
                                            </div>
                                        </ReactFlowProvider>
                                        <TypeConnections />
                                    </div>
                                </Col>
                            </Col>
                            <Flex style={{ padding: "24px 16px" }}>
                                <Text style={{ color: Colors.MainDark300 }}>
                                    {t("Project owner")}:{" "}
                                    <span
                                        style={{
                                            color: Colors.MainDark500,
                                            fontWeight: 700,
                                        }}
                                    >
                                        {project?.user?.name}
                                    </span>
                                </Text>
                            </Flex>
                            <ButtonTransparent
                                handle={contactSales}
                                text={t("Contact sales")}
                                style={{
                                    width: 203,
                                    margin: "0 16px",
                                }}
                            />
                        </>
                    )}
                </Flex>
            </>
        );
    }

    return (
        <>
            <ContactSales open={openContact} setOpen={setOpenContact} />
            <Row style={{ width: "100%", height: "100%" }}>
                {loading && !project?.id && (
                    <Spin
                        style={{
                            margin: "auto",
                            display: "flex",
                            justifyContent: "center",
                            marginTop: "100px",
                        }}
                    />
                )}
                {project?.id && (
                    <>
                        <Col style={{ padding: 32 }} span={8}>
                            <Flex align="center" justify="space-between">
                                <Flex align="center">
                                    <NavLink to={`/${Routes.base}`}>
                                        <ArrowBack />
                                    </NavLink>
                                    <Title
                                        level={3}
                                        style={{
                                            fontWeight: 800,
                                            margin: 0,
                                            marginLeft: 16,
                                        }}
                                    >
                                        {name}
                                    </Title>
                                </Flex>
                                {isAuthorized && (
                                    <Flex>
                                        <ButtonStyle
                                            onClick={handleFavorite}
                                            style={{
                                                backgroundColor:
                                                    Colors.MainDark50,
                                                marginRight: 8,
                                            }}
                                        >
                                            {is_favorite ? (
                                                <FavoriteTrue />
                                            ) : (
                                                <FavoriteFalse />
                                            )}
                                        </ButtonStyle>
                                        <DropDownCardMenu
                                            data={project}
                                            type={TypeDropDown.VIEW}
                                            reactFlowWrapper={reactFlowWrapper}
                                        />
                                    </Flex>
                                )}
                            </Flex>
                            <Flex style={{ marginTop: 24 }}>
                                <Text style={{ color: Colors.MainDark300 }}>
                                    {t("Project owner")}:{" "}
                                    <span
                                        style={{
                                            color: Colors.MainDark500,
                                            fontWeight: 700,
                                        }}
                                    >
                                        {project?.user?.name}
                                    </span>
                                </Text>
                            </Flex>

                            {isAuthorized &&
                                (isSuperAdmin ||
                                    isAdmin ||
                                    (!state_template &&
                                        isUser &&
                                        userInfo?.id === project?.user_id)) && (
                                    <ButtonApprove
                                        style={{ marginRight: 16 }}
                                        text={t("Edit project")}
                                        handle={editProject}
                                    />
                                )}
                            <ButtonTransparent
                                handle={contactSales}
                                text={t("Contact sales")}
                            />
                        </Col>
                        <Col
                            style={{
                                padding: 32,
                                width: "100%",
                                paddingLeft: 0,
                            }}
                            span={16}
                        >
                            <Col
                                style={{
                                    background: Colors.MainDark50,
                                    height: "100%",
                                    borderRadius: 8,
                                }}
                            >
                                <Select
                                    style={{
                                        position: "absolute",
                                        top: 16,
                                        right: 16,
                                        marginTop: 50,
                                        zIndex: 100,
                                        fontSize: 16,
                                        height: 40,
                                        border: `2px solid ${Colors.MainBlue500}`,
                                        width: 170,
                                        backgroundColor: "transparent",
                                    }}
                                    placeholder="Select a simulation"
                                    onChange={handleChange}
                                >
                                    {data?.map((device: any) => (
                                        <Option
                                            key={device.id}
                                            value={device.id}
                                        >
                                            {device.name}
                                        </Option>
                                    ))}
                                </Select>
                                <ButtonTransparent
                                    text={
                                        !simulation
                                            ? t("Start simulation")
                                            : t("Stop simulation")
                                    }
                                    handle={handleSimulation}
                                    style={{
                                        position: "absolute",
                                        top: 16,
                                        right: 16,
                                        marginTop: 0,
                                        zIndex: 100,
                                    }}
                                    iconPosition={"end"}
                                    icon={
                                        !simulation ? (
                                            <ArrowRite />
                                        ) : (
                                            <div className="rounded-triangle" />
                                        )
                                    }
                                />
                                <div
                                    className="providerflow"
                                    style={{ height: "calc(100vh - 128px)" }}
                                >
                                    <ReactFlowProvider>
                                        <div
                                            className="reactflow-wrapper"
                                            ref={reactFlowWrapper}
                                        >
                                            <ReactFlow
                                                nodes={nodes}
                                                edges={edges}
                                                nodeTypes={nodeHandlers}
                                                fitView
                                                maxZoom={2.5}
                                                nodesConnectable={false}
                                            >
                                                <Controls />
                                            </ReactFlow>
                                        </div>
                                    </ReactFlowProvider>
                                    <TypeConnections />
                                </div>
                            </Col>
                        </Col>
                    </>
                )}
            </Row>
        </>
    );
};
