import { Col, Tabs, TabsProps } from "antd";
import { useState } from "react";
import { IconOpenClose } from "shared/icons";
import { DevicesPanel } from "./DevicesPanel";
import { SequenceList } from "../sequence/SequenceList";
import { useTranslation } from "react-i18next";
import { selectUser } from "store/userSlice";
import { useAppSelector } from "store/hooks";
import { ROLES } from "shared/interfaces";

export const Sidebar = () => {
    const [open, setOpen] = useState(true);
    const { t } = useTranslation();
    const { roles } = useAppSelector(selectUser);
    const isSuperAdmin = roles.includes(ROLES.SUPER_ADMIN);
    const isAdmin = roles.includes(ROLES.ADMIN);

    const handleClick = () => {
        setOpen(!open);
    };

    const items: TabsProps["items"] = [
        {
            key: "1",
            label: t("Devices panel"),
            children: <DevicesPanel />,
        },
        {
            key: "2",
            label: t("Sequence list"),
            children: <SequenceList />,
            disabled: !(isSuperAdmin || isAdmin),
        },
    ];
    return (
        <Col
            style={{
                position: "relative",
                minWidth: 10,
                height: "calc(100vh - 140px)",
            }}
        >
            <Col
                style={{
                    position: "absolute",
                    top: "50%",
                    left: open ? -12 : 0,
                    cursor: "pointer",
                    transform: `rotate(${open ? 0 : "180deg"})`,
                    zIndex: 1000,
                }}
                onClick={handleClick}
            >
                <IconOpenClose />
            </Col>
            {open && (
                <aside style={{ padding: "16px 32px" }}>
                    <Tabs defaultActiveKey="1" items={items} animated={false} />
                </aside>
            )}
        </Col>
    );
};
