import { ReactFlow, ReactFlowProvider, useEdgesState } from "@xyflow/react";
import { useEffect, useRef } from "react";
import { Project } from "shared/interfaces";
import { customNodes } from "../Nodes";
import { Flex, Modal, Spin, Typography } from "antd";
import styled from "styled-components";
import { toCanvas } from "html-to-image";
import GIF from "gif.js";
import { ConnectType } from "pages/project/Flow";
import { ButtonApprove, ButtonTransparent } from "../Buttons";
import { useTranslation } from "react-i18next";
import { TypeConnections } from "shared/components";

const { Title } = Typography;

interface IDownloadBlockProps {
    data: Project;
    download: boolean;
    setDownload: (open: boolean) => void;
}

export const GifDownloadModal = (props: IDownloadBlockProps) => {
    const reactFlowWrapper = useRef<any>(null);
    const { data, download, setDownload } = props;
    const { nodes, edges: connection } = data?.template || {};
    const [edges, setEdges] = useEdgesState<any>(connection);
    const { t } = useTranslation();

    useEffect(() => {
        if (download) {
            setEdges((eds: any) => {
                const isAnimated = (type: ConnectType) =>
                    type === ConnectType.LIQUID ||
                    type === ConnectType.PNEUMATIC;
                return eds?.map((edge: any) => ({
                    ...edge,
                    animated: isAnimated(edge.style.stroke) ? true : false,
                }));
            });
            setTimeout(() => {
                downloadGIF();
            }, 0);
        }
    }, [download]);

    const captureFrames = async (delay: number) => {
        const frames = [];
        reactFlowWrapper.current.style.backgroundColor = "#fff";
        for (let i = 0; i < 10; i++) {
            const canvas = await toCanvas(reactFlowWrapper.current);
            frames.push(canvas);
            await new Promise((resolve) => setTimeout(resolve, delay));
        }
        return frames;
    };

    const createGIF = (frames: any, delay: number) => {
        return new Promise((resolve) => {
            const gif = new GIF({
                workers: 2,
                quality: 100,
                workerScript: "/gif.worker.js",
            });

            frames.forEach((frame: any) => {
                gif.addFrame(frame, { delay });
            });

            gif.on("finished", (blob) => {
                resolve(URL.createObjectURL(blob));
            });
            gif.render();
        });
    };

    const downloadGIF = async () => {
        const delay = 120; // Delay between frames in milliseconds
        try {
            const frames = await captureFrames(delay);
            const gifURL = await createGIF(frames, delay);
            const a = document.createElement("a");
            a.href = gifURL as string;
            a.download = `${data.name}.gif`;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        } catch (error) {
            console.error("Error creating GIF:", error);
        } finally {
            setDownload(false);
        }
    };

    const openNewTab = () => {
        const newTabUrl = `${window.location.origin}/`;
        window.open(newTabUrl, "_blank", "noopener,noreferrer");
    };

    const cancleDownload = () => {
        setDownload(false);
    };

    return (
        <ModalStyled
            open={!!download}
            width={"100%"}
            footer={null}
            wrapClassName="custom-modal"
        >
            <Flex
                style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    textAlign: "center",
                    zIndex: 1000,
                }}
                vertical
                justify="center"
                gap={24}
            >
                <Spin />
                <Title level={3}>
                    {t(
                        "Please wait while the download is being prepared. This may take up to one minute."
                    )}
                </Title>
                <Flex justify="center">
                    <ButtonTransparent
                        handle={cancleDownload}
                        style={{ marginTop: 0 }}
                    />
                    <ButtonApprove
                        text={t("Open in new tab")}
                        handle={openNewTab}
                        style={{ margin: "0 16px 0" }}
                    />
                </Flex>
            </Flex>

            <div
                className="providerflow"
                style={{ height: "100%", width: "100%", opacity: 0 }}
            >
                <ReactFlowProvider>
                    <div className="reactflow-wrapper" ref={reactFlowWrapper}>
                        <ReactFlow
                            nodes={nodes}
                            edges={edges}
                            nodeTypes={customNodes(nodes)}
                            fitView
                            maxZoom={3}
                            zoomOnScroll={false}
                        />
                        <TypeConnections />
                    </div>
                </ReactFlowProvider>
            </div>
        </ModalStyled>
    );
};

const ModalStyled = styled(Modal)`
    top: 0;
    padding: 0;
    height: 100%;
    maxwidth: 100%;
    .ant-modal-content {
        //background-color: transparent;
        box-shadow: none;
        height: 100vh;
    }
    .ant-modal-close {
        display: none;
    }
    .ant-modal-body {
        height: 100%;
    }
`;
