import { Handle, useUpdateNodeInternals } from "@xyflow/react";
import Pneumatic from "./connections/Pneumatic.svg";
import Liquid from "./connections/Liquid.svg";
import Sensor from "./connections/Sensor.svg";
import Valve from "./connections/Valve.svg";
import Circle from "./connections/Circle.svg";
import { Col, Flex, Form, Popover } from "antd";
import { INode, INodesNode } from "shared/interfaces";
import { Button, Input } from "antd";
import { useEffect, useRef, useState } from "react";
import { Colors } from "constants/colors";
import { t } from "i18next";
import axios from "axios";
import { ENDPOINTS } from "shared/fethers";
import { openNotification } from "../Notification";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { useAppSelector } from "store/hooks";
import {
    getProject,
    selectProject,
    updateNodes,
    updateProject,
    updateShema,
} from "store/projectSlice";
import { cloneDeep, set } from "lodash";
import { useParams } from "react-router-dom";
import { drag } from "d3-drag";
import { select } from "d3-selection";

const DEFAULT_HANDLE_STYLE = {
    //width: 5,
    //height: 4,
    border: 0,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundColor: "transparent",
};

interface ICustomNodeProps extends INodesNode {
    width?: number;
    height?: number;
    info?: any;
    data?: {
        label: string;
    };
    rotate?: any;
    name?: string;
    id: string;
}

const baseUrl = process.env.REACT_APP_API_URL_IMAGE;

export const CustomNodeHandle = ({
    data,
    props,
}: {
    data: ICustomNodeProps;
    props?: any;
}) => {
    const { id: idProject } = useParams();
    const {
        connectors,
        image,
        width,
        height,
        rotate: rotateNode,
        data: dataProps,
    } = data || {};
    const { id, data: info } = props || {};
    const [inputValue, setInputValue] = useState(
        info?.label ?? dataProps?.label
    );
    const dispatch = useDispatch<AppDispatch>();
    const { temporaryProject, rotate } = useAppSelector(selectProject);
    const { nodes, edges } = temporaryProject?.template || {};

    const handleSave = async () => {
        const updatedProject = cloneDeep(temporaryProject);
        const nodeIndex = updatedProject.template.nodes.findIndex(
            (node: any) => node?.id === id
        );
        if (nodeIndex !== -1) {
            set(
                updatedProject,
                `template.nodes[${nodeIndex}].data.label`,
                inputValue
            );
        }

        await axios
            .put(ENDPOINTS.projectById(idProject || ""), {
                template: updatedProject.template,
            })
            .then(() => {
                openNotification("success");
                setTimeout(() => dispatch(getProject(idProject || "")), 1000);
            })
            .catch(() => openNotification("error"));
    };

    const popoverContent = (
        <Flex gap={8}>
            <Input
                value={inputValue}
                onChange={(e) => {
                    e.stopPropagation();
                    setInputValue(e.target?.value);
                }}
                placeholder="Enter some text"
                style={{ marginBottom: "8px" }}
            />
            <Button
                type="link"
                style={{
                    color: Colors.MainBlue500,
                    fontSize: 16,
                    fontWeight: 700,
                    padding: 0,
                }}
                onClick={handleSave}
            >
                {t("Save")}
            </Button>
        </Flex>
    );

    const changeFlow = (e: any) => {
        e.preventDefault();
        e.stopPropagation();

        // Update connectors only for the node with label "Hello"
        const updatedNodes = temporaryProject.template?.nodes.map(
            (node: any) => {
                if (nodeWithMuxLabel) {
                    const updatedConnectors = node.node.connectors.map(
                        (connector: any) => ({
                            ...connector,
                            type:
                                connector.type === "source"
                                    ? "target"
                                    : "source",
                        })
                    );
                    return {
                        ...node,
                        node: {
                            ...node.node,
                            connectors: updatedConnectors,
                        },
                    };
                }
                return node;
            }
        );

        if (updatedNodes) {
            dispatch(
                updateShema({
                    ...temporaryProject,
                    template: {
                        ...temporaryProject?.template,
                        nodes: updatedNodes,
                    },
                })
            );
            dispatch(
                updateProject({
                    ...temporaryProject,
                    template: {
                        ...temporaryProject?.template,
                        nodes: updatedNodes,
                    },
                })
            );
            dispatch(getProject(idProject ?? ""));
        }
    };

    const nodeWithMuxLabel =
        dataProps?.label === "Mux Recirculation" ||
        data?.name === "Mux Recirculation";

    const [rotation, setRotation] = useState(rotateNode || 0);
    const rotateControlRef = useRef(null);
    const updateNodeInternals = useUpdateNodeInternals();
    const [rotatable, setRotatable] = useState(false);

    useEffect(() => {
        if (!rotateControlRef.current) {
            return;
        }

        const selection = select(rotateControlRef.current);
        const dragHandler: any = drag().on("drag", (evt) => {
            const dx = evt.x - 100;
            const dy = evt.y - 100;
            const rad = Math.atan2(dx, dy);
            const deg = rad * (180 / Math.PI);
            setRotation(180 - deg);
            updateNodeInternals(id);
        });
        selection.call(dragHandler);
    }, [id, updateNodeInternals]);

    useEffect(() => {
        if (!!+rotation && rotation !== "0") {
            dispatch(
                updateNodes(
                    nodes?.map((node: any) => {
                        if (node?.id === id) {
                            return {
                                ...node,
                                rotate: `${rotation}`,
                            };
                        }
                        return node;
                    })
                )
            );
        }
    }, [rotation, id]);

    return (
        <div
            style={{
                transform: `rotate(${rotation}deg)`,
            }}
            className="node"
        >
            {(info?.label || dataProps?.label) && (
                <Popover content={popoverContent} trigger="click">
                    <div
                        style={{
                            position: "absolute",
                            fontSize: 7,
                            top: "-4%",
                            left: "100%",
                            transform: "translateY(-50%)",
                            marginLeft: "5px",
                            whiteSpace: "nowrap",
                            background: "white",
                            cursor: "pointer",
                        }}
                    >
                        {info?.label ?? dataProps?.label}
                    </div>
                </Popover>
            )}
            {rotate && (
                <label
                    style={{
                        position: "absolute",
                        bottom: 5,
                        right: 5,
                        fontSize: 7,
                        background: "white",
                        display: "flex",
                        alignItems: "center",
                        gap: 4,
                        padding: "1px",
                        borderRadius: "3px",
                    }}
                >
                    <input
                        type="checkbox"
                        checked={rotatable}
                        onChange={(evt) => {
                            //evt.stopPropagation();
                            //evt.preventDefault();
                            setRotatable(evt.target.checked);
                        }}
                        style={{ width: 6, height: 6 }}
                    />
                    rotate
                </label>
            )}
            {nodeWithMuxLabel && !edges?.length && nodes?.length === 1 && (
                <button
                    onClick={changeFlow}
                    style={{
                        position: "absolute",
                        top: 5,
                        right: 5,
                        background: "transparent",
                    }}
                >
                    <ChangeFlowIcon rotation={rotation} />
                </button>
            )}

            <div
                ref={rotateControlRef}
                style={{
                    display: rotatable && rotate ? "block" : "none",
                }}
                className={`nodrag rotateHandle`}
            />

            <img
                src={`${baseUrl}` + image}
                alt=""
                width={width}
                height={height}
                style={{
                    maxWidth: width ? width : 50,
                    maxHeight: height ? height : 50,
                }}
            />
            {connectors?.map(
                (
                    {
                        top,
                        left,
                        position,
                        type,
                        connection_type_id,
                        id,
                        connection,
                    }: any,
                    index: number
                ) => {
                    const direction = type === "source";
                    const rotations = {
                        top: direction ? "270deg" : "90deg",
                        right: direction ? "0deg" : "180deg",
                        bottom: direction ? "90deg" : "270deg",
                        left: direction ? "180deg" : "0deg",
                    };
                    const isCustomEdge = connection === "custom";

                    const rotate =
                        rotations[position as keyof typeof rotations] || "0deg";
                    const typeFlow = isCustomEdge
                        ? Circle
                        : connection_type_id === 1
                        ? Pneumatic
                        : connection_type_id === 2
                        ? Liquid
                        : connection_type_id === 3
                        ? Sensor
                        : Valve;

                    return (
                        <Handle
                            type={type === "source" ? "target" : "source"}
                            position={position as any}
                            key={index}
                            id={String(id)}
                            //isConnectable={connections.length < props.connectionCount}
                            style={{
                                ...(!isCustomEdge && DEFAULT_HANDLE_STYLE),
                                backgroundImage: `url(${typeFlow})`,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                backgroundColor: "transparent",
                                top: `${top}%`,
                                left: `${left}%`,
                                transform: `translate(-50%, -50%) rotate(${rotate})`,
                            }}
                        />
                    );
                }
            )}
        </div>
    );
};

export const customNodes = (nodes: any) =>
    nodes?.length
        ? nodes?.reduce((acc: any, item: INode) => {
              if (item?.type && item?.node) {
                  acc[item?.type] = (props: any) => (
                      <CustomNodeHandle
                          data={{
                              ...item?.node,
                              width: item?.width,
                              height: item?.height,
                              ...item,
                          }}
                          props={props}
                      />
                  );
              }
              return acc;
          }, {})
        : [];

export const CustomDeviceImage = (props: any) => {
    const { paramsConnect, image, form, width, height } = props;

    const widthImage = Form.useWatch("width", form);
    const heightImage = Form.useWatch("height", form);

    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const imgRef = useRef(null);

    useEffect(() => {
        if (!width) {
            form.setFieldValue("width", dimensions.width);
        }
        if (!height) {
            form.setFieldValue("height", dimensions.height);
        }
    }, [dimensions]);

    return (
        <div>
            <Col
                style={{
                    maxWidth: 345,
                    height: "100%",
                    position: "relative",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {!!paramsConnect.length &&
                    paramsConnect.map((item: any, index: number) => {
                        const { position, type, connection_type_id } = item;
                        const direction = type === "source";

                        const rotations = {
                            top: direction ? "270deg" : "90deg",
                            right: direction ? "0deg" : "180deg",
                            bottom: direction ? "90deg" : "270deg",
                            left: direction ? "180deg" : "0deg",
                        };

                        const rotate =
                            rotations[position as keyof typeof rotations] ||
                            "0deg";

                        const typeFlow =
                            connection_type_id === 1
                                ? Pneumatic
                                : connection_type_id === 2
                                ? Liquid
                                : connection_type_id === 3
                                ? Sensor
                                : Valve;
                        return (
                            <Flex
                                style={{
                                    position: "absolute",
                                    top: item?.top,
                                    left: item?.left,
                                }}
                                key={index}
                            >
                                <img
                                    src={typeFlow}
                                    alt=""
                                    key={index}
                                    style={{
                                        display: "block",
                                        margin: "0 auto",
                                        transform: `rotate(${rotate})`,
                                        height: 22,
                                        position: "absolute",
                                        top: item?.top,
                                        left: item?.left,
                                        marginLeft: "-10px",
                                        marginTop: "-8px",
                                    }}
                                />
                            </Flex>
                        );
                    })}
                <img
                    ref={imgRef}
                    src={image}
                    alt=""
                    style={{
                        maxWidth: 305,
                    }}
                    onLoad={() => {
                        setDimensions({
                            width: (imgRef.current as any)?.offsetWidth,
                            height: (imgRef.current as any)?.offsetHeight,
                        });
                    }}
                    width={widthImage ? +widthImage : +width}
                    height={heightImage ? +heightImage : +height}
                />
            </Col>
        </div>
    );
};

const ChangeFlowIcon = ({ rotation }: { rotation: number }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        width="15"
        height="15"
        style={{
            transform: `rotate(${rotation ? 0 : 180}deg)`,
            transition: "transform 0.3s ease",
        }}
    >
        <g
            fill="none"
            stroke="#000"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="15" y1="30" x2="85" y2="30" />
            <polyline points="65,10 85,30 65,50" />
        </g>

        <g
            fill="none"
            stroke="#000"
            strokeWidth="5"
            strokeLinecap="round"
            strokeLinejoin="round"
        >
            <line x1="85" y1="70" x2="15" y2="70" />
            <polyline points="35,50 15,70 35,90" />
        </g>
    </svg>
);
